export const GET_VENDORS_DATA_SUCESS = 'GET_VENDORS_DATA_SUCESS'
export const GET_VENDORS_DATA_ERROR = 'GET_VENDORS_DATA_ERROR'

export const GET_VENDOR_SUCESS = 'GET_VENDOR_SUCESS'
export const GET_VENDOR_ERROR = 'GET_VENDOR_ERROR'

export const CREATE_VENDOR_SUCESS = 'CREATE_VENDOR_SUCESS'
export const CREATE_VENDOR_ERROR = 'CREATE_VENDOR_ERROR'

export const UPDATE_VENDOR_SUCESS = 'UPDATE_VENDOR_SUCESS'
export const UPDATE_VENDOR_ERROR = 'UPDATE_VENDOR_ERROR'

export const DELETE_VENDOR_SUCESS = 'DELETE_VENDOR_SUCESS'
export const DELETE_VENDOR_ERROR = 'DELETE_VENDOR_ERROR'

export const ACTIVE_INACTIVE_VENDOR_SUCESS = 'ACTIVE_INACTIVE_VENDOR_SUCESS'
export const ACTIVE_INACTIVE_VENDOR_ERROR = 'ACTIVE_INACTIVE_VENDOR_ERROR'

export const ACCEPT_REJECT_VENDOR_SUCESS = 'ACCEPT_REJECT_VENDOR_SUCESS'
export const ACCEPT_REJECT_VENDOR_ERROR = 'ACCEPT_REJECT_VENDOR_ERROR'