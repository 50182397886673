import * as ACTION_TYPES from './Types'

const initialState = {
  message: '',
  error: null,
  sipData: [],
  sipById: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_SIP_DATA_SUCCESS:
      return {
        ...state,
        sipData: action.payload.data,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.GET_SIP_DATA_ERROR:
      return {
        ...state,
        sipData: [],
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.GET_SIP_SUCCESS:
      return {
        ...state,
        sipById: action.payload,
        message: '',
        error: null,
      }
    case ACTION_TYPES.GET_SIP_ERROR:
      return {
        ...state,
        sipById: null,
        message: '',
        error: action.payload,
      }
    case ACTION_TYPES.CREATE_SIP_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.CREATE_SIP_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.UPDATE_SIP_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.UPDATE_SIP_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.DELETE_SIP_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.DELETE_SIP_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.ACTIVE_INACTIVE_SIP_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.ACTIVE_INACTIVE_SIP_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    default:
      return state
  }
}
