import * as ACTION_TYPES from './Types'

const initialState = {
  message: '',
  error: null,
  vendordData: [],
  vendorById: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_SUBADMIN_DATA_SUCESS:
      return {
        ...state,
        vendorData: action.payload.data,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.GET_SUBADMIN_DATA_ERROR:
      return {
        ...state,
        vendorData: [],
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.GET_SUBADMIN_SUCESS:
      return {
        ...state,
        vendorById: action.payload,
        message: '',
        error: null,
      }
    case ACTION_TYPES.GET_SUBADMIN_ERROR:
      return {
        ...state,
        vendorById: null,
        message: '',
        error: action.payload,
      }
    case ACTION_TYPES.CREATE_SUBADMIN_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.CREATE_SUBADMIN_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.UPDATE_SUBADMIN_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.UPDATE_SUBADMIN_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.DELETE_SUBADMIN_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.DELETE_SUBADMIN_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.ACTIVE_INACTIVE_SUBADMIN_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.ACTIVE_INACTIVE_SUBADMIN_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    default:
      return state
  }
}
