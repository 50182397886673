import * as ACTION_TYPES from './Types'

const initialState = {
  message: '',
  error: null,
  sponsorData: [],
  sponsorById: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_SPONSOR_DATA_SUCCESS:
      return {
        ...state,
        sponsorData: action.payload.data,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.GET_SPONSOR_DATA_ERROR:
      return {
        ...state,
        sponsorData: [],
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.GET_SPONSOR_SUCCESS:
      return {
        ...state,
        sponsorById: action.payload,
        message: '',
        error: null,
      }
    case ACTION_TYPES.GET_SPONSOR_ERROR:
      return {
        ...state,
        sponsorById: null,
        message: '',
        error: action.payload,
      }
    case ACTION_TYPES.CREATE_SPONSOR_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.CREATE_SPONSOR_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.UPDATE_SPONSOR_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.UPDATE_SPONSOR_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.DELETE_SPONSOR_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.DELETE_SPONSOR_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.ACTIVE_INACTIVE_SPONSOR_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.ACTIVE_INACTIVE_SPONSOR_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    default:
      return state
  }
}
