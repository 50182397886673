import * as ACTION_TYPES from './Types'

const initialState = {
  message: '',
  error: null,
  boothdData: [],
  boothById: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_BOOTH_DATA_SUCESS:
      return {
        ...state,
        boothData: action.payload.data,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.GET_BOOTH_DATA_ERROR:
      return {
        ...state,
        boothData: [],
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.GET_BOOTH_SUCESS:
      return {
        ...state,
        boothById: action.payload,
        message: '',
        error: null,
      }
    case ACTION_TYPES.GET_BOOTH_ERROR:
      return {
        ...state,
        boothById: null,
        message: '',
        error: action.payload,
      }
    case ACTION_TYPES.CREATE_BOOTH_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.CREATE_BOOTH_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.UPDATE_BOOTH_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.UPDATE_BOOTH_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.DELETE_BOOTH_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.DELETE_BOOTH_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    default:
      return state
  }
}
