import { combineReducers } from 'redux'
import login from './Login/Reducer'
// import { reducer as toastrReducer } from 'react-redux-toastr'
import customer from './Customer/Reducer'
import artist from './Artist/Reducer'
import sidebar from './sidebar'
import event from './Event/Reducer'
import ticket from './Ticket/Reducer'
import vendor from './Vendor/Reducer'
import serviceTax from './ServiceTax/Reducer'
import subAdmin from './SubAdmin/Reducer'
import booth from './Booth/Reducer'
import foodTruck from './FoodTruck/Reducer'
import vendorServiceTax from './VendorServiceTax/Reducer'
import vipFood from './VIPFood/Reducer'
import sponsor from './Sponsor/Reducer'
import contactUs from './ContactUs/Reducer'
import faq from './FAQ/Reducer'
import coupon from './Coupon/Reducer'
import sip from './SIP/Reducer'
import dashboard from './Dashboard/Reducer'
import boxOffice from './BoxOffice/Reducer'
import attandee from './Attandee/Reducer'

const rootReducer = combineReducers({
  // toastr: toastrReducer,
  login,
  customer,
  artist,
  sidebar,
  event,
  vendor,
  ticket,
  serviceTax,
  subAdmin,
  booth,
  foodTruck,
  vendorServiceTax,
  vipFood,
  contactUs,
  sponsor,
  faq,
  coupon,
  sip,
  dashboard,
  boxOffice,
  attandee,
})

export default rootReducer
