export const GET_COUPON_DATA_SUCESS = 'GET_COUPON_DATA_SUCESS'
export const GET_COUPON_DATA_ERROR = 'GET_COUPON_DATA_ERROR'

export const GET_COUPON_SUCESS = 'GET_COUPON_SUCESS'
export const GET_COUPON_ERROR = 'GET_COUPON_ERROR'

export const CREATE_COUPON_SUCESS = 'CREATE_COUPON_SUCESS'
export const CREATE_COUPON_ERROR = 'CREATE_COUPON_ERROR'

export const UPDATE_COUPON_SUCESS = 'UPDATE_COUPON_SUCESS'
export const UPDATE_COUPON_ERROR = 'UPDATE_COUPON_ERROR'

export const DELETE_COUPON_SUCESS = 'DELETE_COUPON_SUCESS'
export const DELETE_COUPON_ERROR = 'DELETE_COUPON_ERROR'

export const ACTIVE_INACTIVE_COUPON_SUCESS = 'ACTIVE_INACTIVE_COUPON_SUCESS'
export const ACTIVE_INACTIVE_COUPON_ERROR = 'ACTIVE_INACTIVE_COUPON_ERROR'