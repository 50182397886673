export const GET_SIP_DATA_SUCCESS = 'GET_SIP_DATA_SUCCESS'
export const GET_SIP_DATA_ERROR = 'GET_SIP_DATA_ERROR'

export const GET_SIP_SUCCESS = 'GET_SIP_SUCCESS'
export const GET_SIP_ERROR = 'GET_SIP_ERROR'

export const CREATE_SIP_SUCCESS = 'CREATE_SIP_SUCCESS'
export const CREATE_SIP_ERROR = 'CREATE_SIP_ERROR'

export const UPDATE_SIP_SUCCESS = 'UPDATE_SIP_SUCCESS'
export const UPDATE_SIP_ERROR = 'UPDATE_SIP_ERROR'

export const DELETE_SIP_SUCCESS = 'DELETE_SIP_SUCCESS'
export const DELETE_SIP_ERROR = 'DELETE_SIP_ERROR'

export const ACTIVE_INACTIVE_SIP_SUCCESS = 'ACTIVE_INACTIVE_SIP_SUCCESS'
export const ACTIVE_INACTIVE_SIP_ERROR = 'ACTIVE_INACTIVE_SIP_ERROR'
