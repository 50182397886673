import * as ACTION_TYPES from './Types'

const initialState = {
  message: '',
  error: null,
  coupondData: [],
  couponById: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_COUPON_DATA_SUCESS:
      return {
        ...state,
        couponData: action.payload.data,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.GET_COUPON_DATA_ERROR:
      return {
        ...state,
        couponData: [],
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.GET_COUPON_SUCESS:
      return {
        ...state,
        couponById: action.payload,
        message: '',
        error: null,
      }
    case ACTION_TYPES.GET_COUPON_ERROR:
      return {
        ...state,
        couponById: null,
        message: '',
        error: action.payload,
      }
    case ACTION_TYPES.CREATE_COUPON_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.CREATE_COUPON_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.UPDATE_COUPON_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.UPDATE_COUPON_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.DELETE_COUPON_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.DELETE_COUPON_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.ACTIVE_INACTIVE_COUPON_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.ACTIVE_INACTIVE_COUPON_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    default:
      return state
  }
}
