export const GET_ARTISTS_DATA_SUCESS = 'GET_ARTISTS_DATA_SUCESS'
export const GET_ARTISTS_DATA_ERROR = 'GET_ARTISTS_DATA_ERROR'

export const GET_ARTIST_SUCESS = 'GET_ARTIST_SUCESS'
export const GET_ARTIST_ERROR = 'GET_ARTIST_ERROR'

export const CREATE_ARTIST_SUCESS = 'CREATE_ARTIST_SUCESS'
export const CREATE_ARTIST_ERROR = 'CREATE_ARTIST_ERROR'

export const UPDATE_ARTIST_SUCESS = 'UPDATE_ARTIST_SUCESS'
export const UPDATE_ARTIST_ERROR = 'UPDATE_ARTIST_ERROR'

export const DELETE_ARTIST_SUCESS = 'DELETE_ARTIST_SUCESS'
export const DELETE_ARTIST_ERROR = 'DELETE_ARTIST_ERROR'

export const FEATURED_ARTIST_SUCESS = 'FEATURED_ARTIST_SUCESS'
export const FEATURED_ARTIST_ERROR = 'FEATURED_ARTIST_ERROR'
