export const GET_BOOTH_DATA_SUCESS = 'GET_BOOTH_DATA_SUCESS'
export const GET_BOOTH_DATA_ERROR = 'GET_BOOTH_DATA_ERROR'

export const GET_BOOTH_SUCESS = 'GET_BOOTH_SUCESS'
export const GET_BOOTH_ERROR = 'GET_BOOTH_ERROR'

export const CREATE_BOOTH_SUCESS = 'CREATE_BOOTH_SUCESS'
export const CREATE_BOOTH_ERROR = 'CREATE_BOOTH_ERROR'

export const UPDATE_BOOTH_SUCESS = 'UPDATE_BOOTH_SUCESS'
export const UPDATE_BOOTH_ERROR = 'UPDATE_BOOTH_ERROR'

export const DELETE_BOOTH_SUCESS = 'DELETE_BOOTH_SUCESS'
export const DELETE_BOOTH_ERROR = 'DELETE_BOOTH_ERROR'
