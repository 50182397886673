export const GET_EVENTS_DATA_SUCESS = 'GET_EVENTS_DATA_SUCESS'
export const GET_EVENTS_DATA_ERROR = 'GET_EVENTS_DATA_ERROR'

export const GET_EVENT_SUCESS = 'GET_EVENT_SUCESS'
export const GET_EVENT_ERROR = 'GET_EVENT_ERROR'

export const CREATE_EVENT_SUCESS = 'CREATE_EVENT_SUCESS'
export const CREATE_EVENT_ERROR = 'CREATE_EVENT_ERROR'

export const UPDATE_EVENT_SUCESS = 'UPDATE_EVENT_SUCESS'
export const UPDATE_EVENT_ERROR = 'UPDATE_EVENT_ERROR'

export const DELETE_EVENT_SUCESS = 'DELETE_EVENT_SUCESS'
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR'

export const UPLOAD_IMAGE_SUCESS = 'UPLOAD_IMAGE_SUCESS'
export const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR'
