import * as ACTION_TYPES from './Types'

const initialState = {
  message: '',
  error: null,
  graphData: [],
  categoryData: [],
  salesData: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        graphData: action.payload.data,
        message: action.payload.message,
      }
    case ACTION_TYPES.GET_GRAPH_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        graphData: [],
        message: action.payload.message,
      }
    case ACTION_TYPES.GET_TICKET_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        categoryData: action.payload.data,
        message: action.payload.message,
      }
    case ACTION_TYPES.GET_TICKET_CATEGORY_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        categoryData: [],
        message: action.payload.message,
      }
    case ACTION_TYPES.GET_TICKET_SALES_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        salesData: action.payload.data,
        message: action.payload.message,
      }
    case ACTION_TYPES.GET_TICKET_SALES_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        salesData: [],
        message: action.payload.message,
      }
    default:
      return state
  }
}
