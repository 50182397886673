import * as ACTION_TYPES from './Types'

const initialState = {
  message: '',
  error: null,
  artistData: [],
  artistById: null,
  artistFatured: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_ARTISTS_DATA_SUCESS:
      return {
        ...state,
        artistData: action.payload.data,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.GET_ARTISTS_DATA_ERROR:
      return {
        ...state,
        artistData: [],
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.GET_ARTIST_SUCESS:
      return {
        ...state,
        artistById: action.payload,
        message: '',
        error: null,
      }
    case ACTION_TYPES.GET_ARTIST_ERROR:
      return {
        ...state,
        artistById: null,
        message: '',
        error: action.payload,
      }
    case ACTION_TYPES.CREATE_ARTIST_SUCESS:
      return {
        ...state,
        message: 'Artist created successfully',
        error: null,
      }
    case ACTION_TYPES.CREATE_ARTIST_ERROR:
      return {
        ...state,
        message: 'Something went wrong.',
        error: action.payload,
      }
    case ACTION_TYPES.UPDATE_ARTIST_SUCESS:
      return {
        ...state,
        message: 'Artist updated successfully',
        error: null,
      }
    case ACTION_TYPES.UPDATE_ARTIST_ERROR:
      return {
        ...state,
        message: 'Something went wrong.',
        error: action.payload,
      }
    case ACTION_TYPES.DELETE_ARTIST_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.DELETE_ARTIST_ERROR:
      return {
        ...state,
        message: 'Something went wrong.',
        error: action.payload,
      }
    case ACTION_TYPES.FEATURED_ARTIST_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        artistFatured: action.payload,
      }
    case ACTION_TYPES.FEATURED_ARTIST_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    default:
      return state
  }
}
