import * as ACTION_TYPES from './Types'

const initialState = {
  message: '',
  error: null,
  vipFooddData: [],
  vipFoodById: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_VIP_FOOD_DATA_SUCESS:
      return {
        ...state,
        vipFoodData: action.payload.data,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.GET_VIP_FOOD_DATA_ERROR:
      return {
        ...state,
        vipFoodData: [],
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.GET_VIP_FOOD_SUCESS:
      return {
        ...state,
        vipFoodById: action.payload,
        message: '',
        error: null,
      }
    case ACTION_TYPES.GET_VIP_FOOD_ERROR:
      return {
        ...state,
        vipFoodById: null,
        message: '',
        error: action.payload,
      }
    case ACTION_TYPES.CREATE_VIP_FOOD_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.CREATE_VIP_FOOD_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.UPDATE_VIP_FOOD_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.UPDATE_VIP_FOOD_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.DELETE_VIP_FOOD_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.DELETE_VIP_FOOD_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    default:
      return state
  }
}
