import * as ACTION_TYPES from './Types'

const initialState = {
  message: '',
  error: null,
  foodTruckdData: [],
  foodTruckById: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_FOOD_TRUCK_DATA_SUCESS:
      return {
        ...state,
        foodTruckData: action.payload.data,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.GET_FOOD_TRUCK_DATA_ERROR:
      return {
        ...state,
        foodTruckData: [],
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.GET_FOOD_TRUCK_SUCESS:
      return {
        ...state,
        foodTruckById: action.payload,
        message: '',
        error: null,
      }
    case ACTION_TYPES.GET_FOOD_TRUCK_ERROR:
      return {
        ...state,
        foodTruckById: null,
        message: '',
        error: action.payload,
      }
    case ACTION_TYPES.CREATE_FOOD_TRUCK_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.CREATE_FOOD_TRUCK_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.UPDATE_FOOD_TRUCK_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.UPDATE_FOOD_TRUCK_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.DELETE_FOOD_TRUCK_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.DELETE_FOOD_TRUCK_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    default:
      return state
  }
}
