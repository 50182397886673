import * as ACTION_TYPES from './Types'

const initialState = {
  message: '',
  error: null,
  ticketdData: [],
  ticketById: null,
  ticketOrderData: [],
  ticketOrderById: null,
  ticketGuestOrderData: [],
  ticketGuestOrderById: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_TICKETS_DATA_SUCESS:
      return {
        ...state,
        ticketData: action.payload.data,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.GET_TICKETS_DATA_ERROR:
      return {
        ...state,
        ticketData: [],
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.GET_TICKET_ORDER_DATA_SUCESS:
      return {
        ...state,
        ticketOrderData: action.payload.data,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.GET_TICKET_ORDER_DATA_ERROR:
      return {
        ...state,
        ticketOrderById: [],
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.GET_TICKET_ORDER_SUCESS:
      return {
        ...state,
        ticketOrderById: action.payload.data,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.GET_TICKET_ORDER_ERROR:
      return {
        ...state,
        ticketOrderById: null,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.GET_TICKET_GUEST_ORDER_DATA_SUCESS:
      return {
        ...state,
        ticketGuestOrderData: action.payload.data,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.GET_TICKET_GUEST_ORDER_DATA_ERROR:
      return {
        ...state,
        ticketGuestOrderById: [],
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.GET_TICKET_GUEST_ORDER_SUCESS:
      return {
        ...state,
        ticketGuestOrderById: action.payload.data,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.GET_TICKET_GUEST_ORDER_ERROR:
      return {
        ...state,
        ticketGuestOrderById: null,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.RETURN_TICKET_ORDER_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.RETURN_TICKET_ORDER_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.GET_TICKET_SUCESS:
      return {
        ...state,
        ticketById: action.payload,
        message: '',
        error: null,
      }
    case ACTION_TYPES.GET_TICKET_ERROR:
      return {
        ...state,
        ticketById: null,
        message: '',
        error: action.payload,
      }
    case ACTION_TYPES.CREATE_TICKET_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.CREATE_TICKET_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.UPDATE_TICKET_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.UPDATE_TICKET_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.DELETE_TICKET_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.DELETE_TICKET_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    default:
      return state
  }
}
