//LOGIN Actions
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_ERROR = "LOGIN_ERROR"

//AUTHENTICATE Actions
export const AUTHENTICATE_USER = "AUTHENTICATE_USER"
export const AUTHENTICATE_FAILED = "AUTHENTICATE_FAILED"


//LOGOUT Actions
export const LOGOUT = "LOGOUT"

//CHANGE_PASSWORD Actions
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR"

//FORGOT_PASSWORD Actions
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR"

//RESET_PASSWORD Actions
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR"