export const GET_VIP_FOOD_DATA_SUCESS = 'GET_VIP_FOOD_DATA_SUCESS'
export const GET_VIP_FOOD_DATA_ERROR = 'GET_VIP_FOOD_DATA_ERROR'

export const GET_VIP_FOOD_SUCESS = 'GET_VIP_FOOD_SUCESS'
export const GET_VIP_FOOD_ERROR = 'GET_VIP_FOOD_ERROR'

export const CREATE_VIP_FOOD_SUCESS = 'CREATE_VIP_FOOD_SUCESS'
export const CREATE_VIP_FOOD_ERROR = 'CREATE_VIP_FOOD_ERROR'

export const UPDATE_VIP_FOOD_SUCESS = 'UPDATE_VIP_FOOD_SUCESS'
export const UPDATE_VIP_FOOD_ERROR = 'UPDATE_VIP_FOOD_ERROR'

export const DELETE_VIP_FOOD_SUCESS = 'DELETE_VIP_FOOD_SUCESS'
export const DELETE_VIP_FOOD_ERROR = 'DELETE_VIP_FOOD_ERROR'
