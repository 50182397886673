import React, { Component, Suspense } from 'react'
import { Provider } from 'react-redux'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import 'react-toastify/dist/ReactToastify.css'
import store from './store/index'
import 'antd/dist/antd.min.css'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import AuthGuard from './views/pages/Auth/AuthGuard'
import { axiosInterceptor } from './middleware'
import { ToastContainer } from 'react-toastify'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

axiosInterceptor()

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const TicketScan = React.lazy(() => import('./views/pages/Ticket Scanning/TicketScan'))
const ForgotPassword = React.lazy(() => import('./views/pages/Forgot Password/forgotPassword'))
const ResetPassword = React.lazy(() => import('./views/pages/Reset Password/ResetPassword'))

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        {/* <ToastContainer theme='colored' /> */}
        <HashRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route
                exact
                path="/"
                name="Login Page"
                element={
                  <AuthGuard>
                    <Login />
                  </AuthGuard>
                }
              />
              <Route
                path="*"
                name="Home"
                element={
                  <AuthGuard>
                    <DefaultLayout />
                  </AuthGuard>
                }
              />
              <Route
                exact
                path="/forgotpassword"
                name="forgotPassword"
                element={<ForgotPassword />}
              />
              <Route
                exact
                path="/ticketscan"
                name="TicketScan"
                element={
                  <AuthGuard>
                    <TicketScan />
                  </AuthGuard>
                }
              />
              <Route
                path="/reset_password/:token"
                name="ResetPassword"
                element={<ResetPassword />}
              />
            </Routes>
          </Suspense>
        </HashRouter>
      </Provider>
    )
  }
}

export default App
