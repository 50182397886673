import { message } from 'antd'
import * as ACTION_TYPES from './Types'

const initialState = {
  message: '',
  error: null,
  userData: {},
  isLoggedIn: false,
  passwordChange: false,
  isProfileUpdate: false,
  forgotPassword: null,
  resetPassword: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_SUCCESS:
      localStorage.setItem('authToken', JSON.stringify(action.payload.data.token))
      localStorage.setItem('user', JSON.stringify(action.payload))
      return {
        ...state,
        userData: action.payload,
        message: action.payload.message,
        isLoggedIn: true,
        error: null,
      }
    case ACTION_TYPES.LOGIN_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload.data,
        // isLoggedIn: true,
      }
    case ACTION_TYPES.AUTHENTICATE_USER:
      return {
        ...state,
        isLoggedIn: true,
        userData: action.payload,
        error: null,
      }
    case ACTION_TYPES.LOGOUT:
    case ACTION_TYPES.AUTHENTICATE_FAILED:
      localStorage.removeItem('authToken')
      localStorage.removeItem('user')
      return {
        ...state,
        isLoggedIn: false,
        userData: {},
      }
    case ACTION_TYPES.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordChange: true,
        message: action.payload.message,
      }
    case ACTION_TYPES.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: action.payload,
        message: action.payload.message,
      }
    case ACTION_TYPES.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: action.payload,
        message: action.payload.message,
      }
    case ACTION_TYPES.RESET_PASSWORD_ERROR:
      return {
        ...state,
        message: action.payload,
        error: action.payload,
      }
    default:
      return state
  }
}
