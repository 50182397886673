export const GET_FAQ_DATA_SUCCESS = 'GET_FAQ_DATA_SUCCESS'
export const GET_FAQ_DATA_ERROR = 'GET_FAQ_DATA_ERROR'

export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS'
export const GET_FAQ_ERROR = 'GET_FAQ_ERROR'

export const CREATE_FAQ_SUCCESS = 'CREATE_FAQ_SUCCESS'
export const CREATE_FAQ_ERROR = 'CREATE_FAQ_ERROR'

export const UPDATE_FAQ_SUCCESS = 'UPDATE_FAQ_SUCCESS'
export const UPDATE_FAQ_ERROR = 'UPDATE_FAQ_ERROR'

export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS'
export const DELETE_FAQ_ERROR = 'DELETE_FAQ_ERROR'

export const ACTIVE_INACTIVE_FAQ_SUCCESS = 'ACTIVE_INACTIVE_FAQ_SUCCESS'
export const ACTIVE_INACTIVE_FAQ_ERROR = 'ACTIVE_INACTIVE_FAQ_ERROR'
