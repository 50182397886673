import * as ACTION_TYPES from './Types'

const initialState = {
  message: '',
  error: null,
  attandeeData: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_ALL_ATTANDEES_SUCCESS:
      return {
        ...state,
        error: null,
        attandeeData: action.payload.data,
        message: action.payload.message,
      }
    case ACTION_TYPES.GET_ALL_ATTANDEES_ERROR:
      return {
        ...state,
        error: action.payload,
        attandeeData: [],
        message: action.payload.message,
      }
    case ACTION_TYPES.ADD_SPAM_ATTANDEES_SUCCESS:
      return {
        ...state,
        error: null,
        message: action.payload.message,
      }
    case ACTION_TYPES.ADD_SPAM_ATTANDEES_ERROR:
      return {
        ...state,
        error: action.payload,
        message: action.payload.message,
      }
    case ACTION_TYPES.DELETE_SPAM_ATTANDEES_SUCCESS:
      return {
        ...state,
        error: null,
        message: action.payload.message,
      }
    case ACTION_TYPES.DELETE_SPAM_ATTANDEES_ERROR:
      return {
        ...state,
        error: action.payload,
        message: action.payload.message,
      }
    default:
      return state
  }
}
