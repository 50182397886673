export const BOX_OFFICE_LIST_CUSTOMER_SUCCESS = 'GET_BOX_OFFICE_LIST_CUSTOMER_SUCCESS'
export const BOX_OFFICE_LIST_CUSTOMER_ERROR = 'GET_BOX_OFFICE_LIST_CUSTOMER_ERROR'

export const BOX_OFFICE_LIST_TICKET_SUCCESS = 'BOX_OFFICE_LIST_TICKET_SUCCESS'
export const BOX_OFFICE_LIST_TICKET_ERROR = 'BOX_OFFICE_LIST_TICKET_ERROR'

export const BOX_OFFICE_CREATE_USER_SUCCESS = 'BOX_OFFICE_CREATE_USER_SUCCESS'
export const BOX_OFFICE_CREATE_USER_ERROR = 'BOX_OFFICE_CREATE_USER_ERROR'

export const BOX_OFFICE_CREATE_SUCCESS = 'BOX_OFFICE_CREATE_SUCCESS'
export const BOX_OFFICE_CREATE_ERROR = 'BOX_OFFICE_CREATE_ERROR'

export const BOX_OFFICE_APPLY_COUPON_SUCCESS = 'BOX_OFFICE_APPLY_COUPON_SUCCESS'
export const BOX_OFFICE_APPLY_COUPON_ERROR = 'BOX_OFFICE_APPLY_COUPON_ERROR'

export const BOX_OFFICE_REPORT_SUCCESS = 'GET_BOX_OFFICE_REPORT_SUCCESS'
export const BOX_OFFICE_REPORT_ERROR = 'GET_BOX_OFFICE_REPORT_ERROR'
