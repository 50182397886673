export const GET_SPONSOR_DATA_SUCCESS = 'GET_SPONSOR_DATA_SUCCESS'
export const GET_SPONSOR_DATA_ERROR = 'GET_SPONSOR_DATA_ERROR'

export const GET_SPONSOR_SUCCESS = 'GET_SPONSOR_SUCCESS'
export const GET_SPONSOR_ERROR = 'GET_SPONSOR_ERROR'

export const CREATE_SPONSOR_SUCCESS = 'CREATE_SPONSOR_SUCCESS'
export const CREATE_SPONSOR_ERROR = 'CREATE_SPONSOR_ERROR'

export const UPDATE_SPONSOR_SUCCESS = 'UPDATE_SPONSOR_SUCCESS'
export const UPDATE_SPONSOR_ERROR = 'UPDATE_SPONSOR_ERROR'

export const DELETE_SPONSOR_SUCCESS = 'DELETE_SPONSOR_SUCCESS'
export const DELETE_SPONSOR_ERROR = 'DELETE_SPONSOR_ERROR'

export const ACTIVE_INACTIVE_SPONSOR_SUCCESS = 'ACTIVE_INACTIVE_SPONSOR_SUCCESS'
export const ACTIVE_INACTIVE_SPONSOR_ERROR = 'ACTIVE_INACTIVE_SPONSOR_ERROR'
