export const GET_VENDOR_SERVICE_TAX_DATA_SUCESS = 'GET_VENDOR_SERVICE_TAX_DATA_SUCESS'
export const GET_VENDOR_SERVICE_TAX_DATA_ERROR = 'GET_VENDOR_SERVICE_TAX_DATA_ERROR'

export const GET_VENDOR_SERVICE_TAX_SUCESS = 'GET_VENDOR_SERVICE_TAX_SUCESS'
export const GET_VENDOR_SERVICE_TAX_ERROR = 'GET_VENDOR_SERVICE_TAX_ERROR'

export const CREATE_VENDOR_SERVICE_TAX_SUCESS = 'CREATE_VENDOR_SERVICE_TAX_SUCESS'
export const CREATE_VENDOR_SERVICE_TAX_ERROR = 'CREATE_VENDOR_SERVICE_TAX_ERROR'

export const UPDATE_VENDOR_SERVICE_TAX_SUCESS = 'UPDATE_VENDOR_SERVICE_TAX_SUCESS'
export const UPDATE_VENDOR_SERVICE_TAX_ERROR = 'UPDATE_VENDOR_SERVICE_TAX_ERROR'

export const DELETE_VENDOR_SERVICE_TAX_SUCESS = 'DELETE_VENDOR_SERVICE_TAX_SUCESS'
export const DELETE_VENDOR_SERVICE_TAX_ERROR = 'DELETE_VENDOR_SERVICE_TAX_ERROR'
