import * as ACTION_TYPES from './Types'

const initialState = {
  message: '',
  error: null,
  faqData: [],
  faqById: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_FAQ_DATA_SUCCESS:
      return {
        ...state,
        faqData: action.payload.data,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.GET_FAQ_DATA_ERROR:
      return {
        ...state,
        faqData: [],
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.GET_FAQ_SUCCESS:
      return {
        ...state,
        faqById: action.payload,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.GET_FAQ_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.CREATE_FAQ_SUCCESS:
      return {
        ...state,
        faqById: action.payload,
        message: '',
        error: null,
      }
    case ACTION_TYPES.CREATE_FAQ_ERROR:
      return {
        ...state,
        faqById: null,
        message: '',
        error: action.payload,
      }
    case ACTION_TYPES.UPDATE_FAQ_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.UPDATE_FAQ_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.DELETE_FAQ_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.DELETE_FAQ_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    default:
      return state
  }
}
