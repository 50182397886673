import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

let AuthGuard = ({ children }) => {
  const navigate = useNavigate()

  // const isLoggedIn = useSelector((s) => !!s?.login?.userData?.data?.token)
  const isLoggedIn = localStorage.getItem('authToken')
  console.log(isLoggedIn)

  useEffect(() => {
    if (!isLoggedIn) navigate('/')
  }, [isLoggedIn, navigate])

  return <>{children}</>
}

export default AuthGuard
