export const GET_SUBADMIN_DATA_SUCESS = 'GET_SUBADMIN_DATA_SUCESS'
export const GET_SUBADMIN_DATA_ERROR = 'GET_SUBADMIN_DATA_ERROR'

export const GET_SUBADMIN_SUCESS = 'GET_SUBADMIN_SUCESS'
export const GET_SUBADMIN_ERROR = 'GET_SUBADMIN_ERROR'

export const CREATE_SUBADMIN_SUCESS = 'CREATE_SUBADMIN_SUCESS'
export const CREATE_SUBADMIN_ERROR = 'CREATE_SUBADMIN_ERROR'

export const UPDATE_SUBADMIN_SUCESS = 'UPDATE_SUBADMIN_SUCESS'
export const UPDATE_SUBADMIN_ERROR = 'UPDATE_SUBADMIN_ERROR'

export const DELETE_SUBADMIN_SUCESS = 'DELETE_SUBADMIN_SUCESS'
export const DELETE_SUBADMIN_ERROR = 'DELETE_SUBADMIN_ERROR'

export const ACTIVE_INACTIVE_SUBADMIN_SUCESS = 'ACTIVE_INACTIVE_SUBADMIN_SUCESS'
export const ACTIVE_INACTIVE_SUBADMIN_ERROR = 'ACTIVE_INACTIVE_SUBADMIN_ERROR'