export const GET_FOOD_TRUCK_DATA_SUCESS = 'GET_FOOD_TRUCK_DATA_SUCESS'
export const GET_FOOD_TRUCK_DATA_ERROR = 'GET_FOOD_TRUCK_DATA_ERROR'

export const GET_FOOD_TRUCK_SUCESS = 'GET_FOOD_TRUCK_SUCESS'
export const GET_FOOD_TRUCK_ERROR = 'GET_FOOD_TRUCK_ERROR'

export const CREATE_FOOD_TRUCK_SUCESS = 'CREATE_FOOD_TRUCK_SUCESS'
export const CREATE_FOOD_TRUCK_ERROR = 'CREATE_FOOD_TRUCK_ERROR'

export const UPDATE_FOOD_TRUCK_SUCESS = 'UPDATE_FOOD_TRUCK_SUCESS'
export const UPDATE_FOOD_TRUCK_ERROR = 'UPDATE_FOOD_TRUCK_ERROR'

export const DELETE_FOOD_TRUCK_SUCESS = 'DELETE_FOOD_TRUCK_SUCESS'
export const DELETE_FOOD_TRUCK_ERROR = 'DELETE_FOOD_TRUCK_ERROR'
