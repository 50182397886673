import * as ACTION_TYPES from './Types'

const initialState = {
  message: '',
  error: null,
  usersData: [],
  ticketData: [],
  reportdata: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.BOX_OFFICE_LIST_CUSTOMER_SUCCESS:
      return {
        ...state,
        error: null,
        usersData: action.payload.data,
        message: action.payload.message,
      }
    case ACTION_TYPES.BOX_OFFICE_LIST_CUSTOMER_ERROR:
      return {
        ...state,
        error: action.payload,
        usersData: [],
        message: action.payload.message,
      }
    case ACTION_TYPES.BOX_OFFICE_LIST_TICKET_SUCCESS:
      return {
        ...state,
        error: null,
        ticketData: action.payload.data,
        message: action.payload.message,
      }
    case ACTION_TYPES.BOX_OFFICE_LIST_TICKET_ERROR:
      return {
        ...state,
        error: action.payload,
        ticketData: [],
        message: action.payload.message,
      }
    case ACTION_TYPES.BOX_OFFICE_CREATE_USER_SUCCESS:
      return {
        ...state,
        error: null,
        message: action.payload.message,
      }
    case ACTION_TYPES.BOX_OFFICE_CREATE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        message: action.payload.message,
      }
    case ACTION_TYPES.BOX_OFFICE_CREATE_SUCCESS:
      return {
        ...state,
        error: null,
        message: action.payload.message,
      }
    case ACTION_TYPES.BOX_OFFICE_CREATE_ERROR:
      return {
        ...state,
        error: action.payload,
        message: action.payload.message,
      }
    case ACTION_TYPES.BOX_OFFICE_APPLY_COUPON_SUCCESS:
      return {
        ...state,
        error: null,
        message: action.payload.message,
      }
    case ACTION_TYPES.BOX_OFFICE_APPLY_COUPON_ERROR:
      return {
        ...state,
        error: action.payload,
        message: action.payload.message,
      }
    case ACTION_TYPES.BOX_OFFICE_REPORT_SUCCESS:
      return {
        ...state,
        error: null,
        reportdata: action.payload.data,
        message: action.payload.message,
      }
    case ACTION_TYPES.BOX_OFFICE_REPORT_ERROR:
      return {
        ...state,
        error: action.payload,
        reportdata: [],
        message: action.payload.message,
      }
    default:
      return state
  }
}
