import * as ACTION_TYPES from './Types'

const initialState = {
  message: '',
  error: null,
  customerData: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.CUSTOMER_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        customerData: action.payload.data,
        message: action.payload.message,
      }
    case ACTION_TYPES.CUSTOMER_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        customerData: [],
        message: action.payload.message,
      }
    case ACTION_TYPES.CUSTOMER_DATA_ACTIVE_INACTIVE_SUCCESS:
      return {
        ...state,
        error: null,
        customerData: action.payload.data,
        message: action.payload.message,
      }
    case ACTION_TYPES.CUSTOMER_DATA_ACTIVE_INACTIVE_ERROR:
      return {
        ...state,
        error: action.payload,
        customerData: [],
        message: action.payload.message,
      }
    default:
      return state
  }
}
