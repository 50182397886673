export const GET_TICKETS_DATA_SUCESS = 'GET_TICKETS_DATA_SUCESS'
export const GET_TICKETS_DATA_ERROR = 'GET_TICKETS_DATA_ERROR'

export const GET_TICKET_SUCESS = 'GET_TICKET_SUCESS'
export const GET_TICKET_ERROR = 'GET_TICKET_ERROR'

export const CREATE_TICKET_SUCESS = 'CREATE_TICKET_SUCESS'
export const CREATE_TICKET_ERROR = 'CREATE_TICKET_ERROR'

export const UPDATE_TICKET_SUCESS = 'UPDATE_TICKET_SUCESS'
export const UPDATE_TICKET_ERROR = 'UPDATE_TICKET_ERROR'

export const DELETE_TICKET_SUCESS = 'DELETE_TICKET_SUCESS'
export const DELETE_TICKET_ERROR = 'DELETE_TICKET_ERROR'

export const GET_TICKET_ORDER_DATA_SUCESS = 'GET_TICKET_ORDER_DATA_SUCESS'
export const GET_TICKET_ORDER_DATA_ERROR = 'GET_TICKET_ORDER_DATA_ERROR'

export const GET_TICKET_ORDER_SUCESS = 'GET_TICKET_ORDER_SUCESS'
export const GET_TICKET_ORDER_ERROR = 'GET_TICKET_ORDER_ERROR'

export const GET_TICKET_GUEST_ORDER_DATA_SUCESS = 'GET_TICKET_GUEST_ORDER_DATA_SUCESS'
export const GET_TICKET_GUEST_ORDER_DATA_ERROR = 'GET_TICKET_GUEST_ORDER_DATA_ERROR'

export const GET_TICKET_GUEST_ORDER_SUCESS = 'GET_TICKET_GUEST_ORDER_SUCESS'
export const GET_TICKET_GUEST_ORDER_ERROR = 'GET_TICKET_GUEST_ORDER_ERROR'

export const RETURN_TICKET_ORDER_SUCESS = 'RETURN_TICKET_ORDER_SUCESS'
export const RETURN_TICKET_ORDER_ERROR = 'RETURN_TICKET_ORDER_ERROR'
