import * as ACTION_TYPES from './Types'

const initialState = {
  message: '',
  error: null,
  eventData: [],
  eventById: null,
  imageData: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_EVENTS_DATA_SUCESS:
      return {
        ...state,
        eventData: action.payload.data,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.GET_EVENTS_DATA_ERROR:
      return {
        ...state,
        eventData: [],
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.GET_EVENT_SUCESS:
      return {
        ...state,
        eventById: action.payload,
        message: '',
        error: null,
      }
    case ACTION_TYPES.GET_EVENT_ERROR:
      return {
        ...state,
        eventById: null,
        message: '',
        error: action.payload,
      }
    case ACTION_TYPES.CREATE_EVENT_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.CREATE_EVENT_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.UPDATE_EVENT_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.UPDATE_EVENT_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.DELETE_EVENT_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.DELETE_EVENT_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    case ACTION_TYPES.UPLOAD_IMAGE_SUCESS:
      return {
        ...state,
        message: action.payload.message,
        imageData: action.payload,
      }
    case ACTION_TYPES.UPLOAD_IMAGE_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload,
      }
    default:
      return state
  }
}
