export const GET_SERVICE_TAX_DATA_SUCESS = 'GET_SERVICE_TAX_DATA_SUCESS'
export const GET_SERVICE_TAX_DATA_ERROR = 'GET_SERVICE_TAX_DATA_ERROR'

export const GET_SERVICE_TAX_SUCESS = 'GET_SERVICE_TAX_SUCESS'
export const GET_SERVICE_TAX_ERROR = 'GET_SERVICE_TAX_ERROR'

export const CREATE_SERVICE_TAX_SUCESS = 'CREATE_SERVICE_TAX_SUCESS'
export const CREATE_SERVICE_TAX_ERROR = 'CREATE_SERVICE_TAX_ERROR'

export const UPDATE_SERVICE_TAX_SUCESS = 'UPDATE_SERVICE_TAX_SUCESS'
export const UPDATE_SERVICE_TAX_ERROR = 'UPDATE_SERVICE_TAX_ERROR'

export const DELETE_SERVICE_TAX_SUCESS = 'DELETE_SERVICE_TAX_SUCESS'
export const DELETE_SERVICE_TAX_ERROR = 'DELETE_SERVICE_TAX_ERROR'
